@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");

body {
  font-family: Arial, Helvetica, sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
